@import 'styles/Theme.scss';

.container {
  @include column();
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  .wrapper {
    width: calc(min(100vw, 520px));
    position: relative;
    @include column();
    justify-content: center;
    height: calc(min(100vh, 520px));
    .logoSad {
      opacity: 0;
      animation: fadeIn 2s linear 0s forwards;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      @include column();
      justify-content: center;
      align-items: center;
      h1 {
        text-align: center;
      }
      h2 {
        text-align: center;
      }
      svg {
        height: 64px;
        margin-bottom: 2rem;
      }
    }

    .logoNormal {
      opacity: 1;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      @include column();
      justify-content: center;
      align-items: center;
      animation: fadeOut 2s linear 0s forwards;
      .title {
        height: 64px;
        width: auto;
        margin-bottom: 4rem;
      }
      svg {
        width: 128px;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
